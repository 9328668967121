import React, { useState, useEffect, useRef, lazy } from 'react'
import { Toast, InputItem, Picker, List, Icon, Modal } from 'antd-mobile'
import { throttle } from 'throttle-debounce'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { JSEncrypt } from 'jsencrypt'
import { ShowEditModal } from '../../utility/modal'
import { request } from '../../utility/request'
import { config } from '../../utility/config'
import { useTitle } from '../../hooks/useTitle'
import { useQuery } from '../../hooks/useQuery'
import { createCaptcha } from '../../utility/utils'
import { useWechat } from '../../contexts/wechat'
import './register.css'

// 区域缓存时间 1小时
const AREA_CACHE_TIME = 1000 * 60 * 60
const dir = config.dir

const Privacy = lazy(() => import(`./${dir}/privacy`))
const ToS = lazy(() => import(`./${dir}/tos`))

const RegStep = (props) => {
  const { history } = props

  const invite = localStorage.getItem('invite_code')
  const localPhone = localStorage.getItem('invite_phone')
  const localProvince = localStorage.getItem('invite_province')
  const localCity = localStorage.getItem('invite_city')
  const localArea = localStorage.getItem('invite_area')
  const hasLocalArea = Boolean(localProvince && localCity && localArea)

  const query = useQuery()
  const [telTipVisible, setTelTipVisible] = useState(false)
  const [tel, setTel] = useState(localPhone || query.get('phone') || '')
  const [verify, setVerify] = useState()
  const [name, setName] = useState()
  const [areaListFetched, setAreaListFetched] = useState(false)
  const [area, setArea] = useState(
    hasLocalArea ? [localProvince, localCity, localArea] : []
  )
  const [shower, setShower] = useState(false)
  const [countdown, setCd] = useState(60)
  const [if_b, setBeg] = useState(false)
  const [visible, setVisible] = useState(false)
  const [protocalType, setPT] = useState(1)
  const [password, setPassword] = useState('')
  const [tipAppear, setTipAppear] = useState(false)
  const inputRef1 = useRef()
  const inputRef2 = useRef()
  const inputRef3 = useRef()
  const inputRef4 = useRef()

  const { showCaptcha, captchaId } = useWechat()

  useTitle(`免费注册${config.appName}`)

  useEffect(() => {
    if (!window.TencentCaptcha) {
      const script = document.createElement('script')
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      script.async = true
      document.getElementById('regPage') &&
        document.getElementById('regPage').appendChild(script)
    }
  }, [])

  const handleAreaChange = (val) => {
    setTipAppear(true)
    setArea(val)
  }
  const pickerChild = React.useMemo(
    () => (
      <div className="reg-row block-f">
        <img src={require('../../images/add-reg.png').default} alt="" />
        <PickTest
          handleAreaChange={handleAreaChange}
          area={area}
          disabled={hasLocalArea}
          onListChange={() => setAreaListFetched(true)}
        />
      </div>
    ),
    [area, hasLocalArea]
  )

  useEffect(() => {
    let timer
    if (if_b) {
      timer = setInterval(() => {
        setCd((x) => x - 1)
        if (countdown <= 1) {
          setCd(60)
          setBeg(false)
        }
      }, 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [if_b, countdown])

  useEffect(() => {
    // 获取到地址列表后再获取地理位置，有本地数据时不自动获取
    if (!areaListFetched || hasLocalArea) return

    window.wx.ready(() => {
      window.wx.hideOptionMenu()
      window.wx.getLocation({
        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: (res) => {
          const areaInfo = JSON.parse(localStorage.getItem('areaInfo')) || {
            expiredAt: Date.now(),
          }

          // 一段时间内不重复请求
          if (areaInfo?.expiredAt > Date.now()) {
            if (areaInfo.area) {
              setArea(areaInfo.area)
            }

            return
          }

          // 根据经纬度获取地区ID
          request({
            url: 'Area.GetAreaIdByCoordinates',
            method: 'POST',
            body: qs.stringify({
              Longitude: res.longitude, // 纬度，浮点数，范围为90 ~ -90
              Latitude: res.latitude, // 经度，浮点数，范围为180 ~ -180
            }),
          }).then((res) => {
            if (res.flag !== 200) return

            const { provinceId, cityId, areaId } = res.data
            const areaArray = [provinceId, cityId, areaId]

            setArea(areaArray)
            localStorage.setItem(
              'areaInfo',
              JSON.stringify({
                area: areaArray,
                expiredAt: Date.now() + AREA_CACHE_TIME,
              })
            )
          })
        },
        fail: (res) => {
          console.error('获取地理位置失败：', res)
        },
      })
    })

    window.wx.error((res) => {
      console.error('获取地理位置失败：', res)
    })
  }, [areaListFetched, hasLocalArea])

  const getVerify = () => {
    if (!tel || tel.length < 11) {
      return
    } else if (if_b) {
      return
    }

    createCaptcha({ showCaptcha, captchaId })
      .then((res) =>
        request({
          url: 'Shop.RegisterVerifycode',
          method: 'POST',
          body: qs.stringify({
            MemberPhone: tel,
            ticket: res.ticket,
            randStr: res.randstr,
            isNew: 1,
            captcha_appid: captchaId,
          }),
        }).then(function (res) {
          if (res.flag === 200) {
            setBeg(true)
            Toast.success('发送成功', 1.5)
          } else if (res.flag === 410) {
            Modal.alert(
              '',
              <>
                该账号已完成注册
                <br />
                下载APP立即体验
              </>,
              [
                { text: '取消' },
                {
                  text: '去下载',
                  onPress: () => history.push('/regdone'),
                },
              ]
            )
          } else if (res.flag === 415) {
            Toast.info(res.msg, 5)
          } else {
            Toast.info(res.msg)
          }
        })
      )
      .then(function (res) {
        if (res.flag === 200) {
          setBeg(true)
          Toast.success('发送成功', 1.5)
        } else if (res.flag === 415) {
          Toast.info(res.msg, 5)
        } else {
          Toast.info(res.msg)
        }
      })
  }

  const rsa = (pwd) => {
    let encrypt = new JSEncrypt(),
      publicCode =
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDw+5+qn3cG6ZrkpwBpBFlx1a8t1PaVfxLQ1XhWz1OkK9B568P5WUU1lLkNKAm8QJyvh9/vXlMvS2JREnSmCY2Q7YarxKtqq34L6hLBh0attf5vUr9U8x6vcDwbrcNdak/uCJKcV0dYLjV0FKs4iLOAZmk40leZmbmu0pm+ckzG+QIDAQAB'
    encrypt.setPublicKey(publicCode)
    return encrypt.encrypt(pwd)
  }

  const sub = throttle(1500, () => {
    if (!tel) {
      Toast.info('请输入正确手机号', 1.2)
      return
    } else if (!verify) {
      Toast.info('请输入验证码', 1.2)
      return
    } else if (!password) {
      Toast.info('请输入密码', 1.2)
      return
    } else if (!name) {
      Toast.info('请输入用户名', 1.2)
      return
    } else if (!shower) {
      Toast.info('请阅读用户协议', 1.2)
      return
    } else if (!area || area.length < 3 || area.indexOf(null) > -1) {
      Toast.info('请选择正确所在地', 1.2)
      return
    }

    Toast.loading('请稍候', 0, null, true)

    request({
      url: 'Shop.RegisterOrder',
      method: 'POST',
      body: qs.stringify({
        ShopID: localStorage.getItem('reg_shopid'),
        VerifyCode: verify,
        InviteCode: invite,
        MemberName: name,
        MemberPwd: rsa(password),
        MemberPhone: tel,
        Province: area[0],
        City: area[1],
        Area: area[2],
        //Address: address
      }),
    }).then(function (res) {
      Toast.hide()
      if (res.flag === 200) {
        Toast.success(
          '注册成功！',
          1.4,
          () => props.history.push('/regdone'),
          true
        )
      } else {
        Toast.info(res.msg, 1.4)
      }
    })
  })

  const ant_protocal = (t) => {
    setPT(t)
    setVisible(true)
  }

  const handleCancel = () => setVisible(false)

  return (
    <div
      style={{ minHeight: '100vh', paddingTop: 8, paddingBottom: 50 }}
      className="reg-page"
      id="regPage"
    >
      {telTipVisible && (
        <div className="tel-tips">
          <Icon
            className="tel-icon"
            type="check-circle"
            size="xs"
            color="#3eb69d"
          />
          <span>
            注册请一定使用您本人实名的手机号，否则将无法通过实名认证，也无法提现哦。
          </span>
        </div>
      )}

      <div
        className="row-line-box reg-grey-lines reg-row"
        style={{ marginTop: 15 }}
        onClick={() => inputRef1.current.focus()}
      >
        <img src={require('../../images/cellph.png').default} alt="" />
        <InputItem
          ref={inputRef1}
          placeholder="请输入您本人实名的手机号"
          maxLength={11}
          type="number"
          value={tel}
          readOnly={!!localPhone}
          onChange={(e) => setTel(e)}
          onFocus={() => setTelTipVisible(true)}
          onBlur={() => setTelTipVisible(false)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 35px 13px',
        }}
        onClick={() => inputRef2.current.focus()}
      >
        <div
          className="reg-grey-lines reg-row"
          style={{ width: '63%', margin: 0 }}
        >
          <img src={require('../../images/locker.png').default} alt="" />
          <InputItem
            ref={inputRef2}
            placeholder="请输入短信验证码"
            value={verify}
            onChange={(e) => setVerify(e)}
          />
        </div>
        <button
          className={`getVerify ${if_b ? 'getVerify--disabled' : ''}`}
          disabled={if_b}
          onClick={getVerify}
        >
          {if_b ? '剩余' + countdown + '秒' : '获取验证码'}
        </button>
      </div>
      <div
        className="row-line-box reg-grey-lines reg-row"
        onClick={() => inputRef3.current.focus()}
      >
        <img src={require('../../images/sq-password.png').default} alt="" />
        <InputItem
          ref={inputRef3}
          placeholder="请设置登录密码"
          value={password}
          onChange={(e) => setPassword(e)}
        />
      </div>
      <div
        className="row-line-box reg-grey-lines reg-row"
        onClick={() => inputRef4.current.focus()}
      >
        <img src={require('../../images/person-reg.png').default} alt="" />
        <InputItem
          ref={inputRef4}
          placeholder="请输入您的店铺名"
          value={name}
          onChange={(e) => setName(e)}
        />
      </div>
      {pickerChild}

      {tipAppear && (
        <div className="red-tips-font">
          请确保您所选区域为您的常驻区域，如发现区域造假，平台将采取一定的惩罚措施。
        </div>
      )}

      <div className="row-line-box reg-grey-lines reg-row">
        <img src={require('../../images/invite-code.png').default} alt="" />
        <InputItem readOnly value={invite || ''} />
      </div>
      {/* <div className="row-line-box reg-grey-lines" style={{ borderBottom: 'none', borderTop: '1px solid #eaeaea', marginTop: 10 }}>
                <InputItem placeholder="详细地址" value={address} onChange={(e) => setAdd(e)} />
            </div> */}

      <div
        className="row-line-box"
        style={{
          alignItems: 'flex-start',
          margin: '0 35px',
          padding: 0,
          border: 'none',
        }}
      >
        <div style={{ width: '6%', display: 'flex', alignItems: 'center' }}>
          {!shower ? (
            <span className="darkness" onClick={() => setShower(true)}></span>
          ) : (
            <Icon
              type="check-circle"
              size="xs"
              color="#3eb69d"
              style={{ marginTop: 2 }}
              onClick={() => setShower(false)}
            />
          )}
        </div>

        <div style={{ marginLeft: 8 }}>
          我已同意并阅读
          <span onClick={() => ant_protocal(1)}>
            <span style={{ color: '#3eb69d' }}>
              《{config.appName}平台服务协议》
            </span>
          </span>
          及
          <span onClick={() => ant_protocal(2)}>
            <span style={{ color: '#3eb69d' }}>
              《{config.appName}隐私协议》
            </span>
          </span>
        </div>
      </div>

      <button type="button" className="sub" onClick={sub}>
        免 费 注 册
      </button>
      <div style={{ textAlign: 'center' }}>
        <Link className="return-oversea" to="/regstepoversea">
          中国港澳台/海外用户注册 <Icon type="right" size="xs" />
        </Link>
      </div>

      <div
        className="logo"
        style={{ marginBottom: 20, marginTop: 30, textAlign: 'center' }}
      >
        <img
          src={require(`../../images/${dir}/nav-logo.png`).default}
          alt=""
          style={{ width: 'auto', height: 37 }}
        />
      </div>
      <ShowEditModal
        visible={visible}
        popupVal={true}
        handleCancel={handleCancel}
        anType="slide-up"
        transparent={false}
        formContent={protocalType === 1 ? <ToS /> : <Privacy />}
      />
    </div>
  )
}

class PickTest extends React.Component {
  //省市区选择组件
  state = {
    finalVal: this.props.area,
    district: [],
  }
  componentDidMount() {
    request({
      url: 'Area.AreaList',
      method: 'POST',
      body: qs.stringify({
        platform: 3,
      }),
    }).then((res) => {
      if (res.flag !== 200) return

      this.setState({
        district: res.data,
      })
      this.props.onListChange(res.data)
    })
  }
  onOk = (v) => {
    this.setState({
      finalVal: v,
    })
    this.props.handleAreaChange(v)
  }
  render() {
    const { area, disabled } = this.props
    const { district } = this.state
    return (
      <Picker
        extra="请选择您的所在地"
        data={district}
        onOk={this.onOk}
        title="地区选择"
        value={area}
        itemStyle={{ flexBasis: '60%' }}
        disabled={disabled}
      >
        <List.Item arrow="horizontal"></List.Item>
      </Picker>
    )
  }
}

export default RegStep
