import fetch from '../utility/fetcher'
import { cRequest } from '../utility/http-instance'

export type ShopMessageParams = {
  ShopID: string
  Content: string
  OrderCode?: string
}

/**
 * 添加留言
 */
export const createShopMessage = (data: ShopMessageParams) =>
  fetch({
    Url: 'Shop.AddGuestbook',
    Key: localStorage.getItem('c_token'),
    ...data,
  })

// 定义基本类型
type BaseInviteCodeInfo = {
  apply_id: number
  invite_code: string
}
// 定义大陆类型
type MainlandInviteCodeInfo = BaseInviteCodeInfo & {
  invitee_phone: string
  invitee_province: number
  invitee_city: number
  invitee_area: number
}
// 定义海外类型
type OverseasInviteCodeInfo = BaseInviteCodeInfo & {
  invitee_phone: string
  invitee_country_id: string
}

// 合并所有类型
export type InviteCodeInfo = MainlandInviteCodeInfo | OverseasInviteCodeInfo

// 判断是否是大陆类型
export function isMainlandInviteCode(
  info: InviteCodeInfo
): info is MainlandInviteCodeInfo {
  return 'invitee_province' in info
}

// 判断是否是海外类型
export function isOverseasInviteCode(
  info: InviteCodeInfo
): info is OverseasInviteCodeInfo {
  return 'invitee_country_id' in info
}

/**
 * 获取邀请码信息
 */
export function getInviteCodeInfo(inviteCode: string) {
  return cRequest.post<{ invite_code_info: InviteCodeInfo | null }>(
    'Shop.InviteCode',
    { InviteCode: inviteCode }
  )
}
