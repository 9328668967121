import { useState, useEffect, useRef, lazy } from 'react'
import { Toast, InputItem, Picker, List, Icon, Modal } from 'antd-mobile'
import { throttle } from 'throttle-debounce'
import { Link } from 'react-router-dom'
import qs from 'qs'
import { JSEncrypt } from 'jsencrypt'
import { ShowEditModal } from '../../utility/modal'
import { request } from '../../utility/request'
import { config } from '../../utility/config'
import { createCaptcha, isChinaMainlandPhoneNumber } from '../../utility/utils'
import { useTitle } from '../../hooks/useTitle'
import { useWechat } from '../../contexts/wechat'
import './register.css'

const dir = config.dir

const Privacy = lazy(() => import(`./${dir}/privacy`))
const ToS = lazy(() => import(`./${dir}/tos`))

const RegStep = (props) => {
  const invite = localStorage.getItem('invite_code')
  const localCountry = localStorage.getItem('invite_country')
  const localPhone = localStorage.getItem('invite_phone')

  const [tel, setTel] = useState(localPhone || '')
  const [verify, setVerify] = useState()
  const [name, setName] = useState()
  const [shower, setShower] = useState(false)
  const [countdown, setCd] = useState(60)
  const [if_b, setBeg] = useState(false)
  const [visible, setVisible] = useState(false)
  const [protocalType, setPT] = useState(1)
  const [password, setPassword] = useState('')
  // const [tipAppear, setTipAppear] = useState(false);
  const [countryList, setCountryList] = useState([])
  const [countryid, setCountryid] = useState(localCountry || '')

  const inputRef1 = useRef()
  const inputRef2 = useRef()
  const inputRef3 = useRef()
  const inputRef4 = useRef()

  const { showCaptcha, captchaId } = useWechat()

  useTitle(`免费注册${config.appName}`)

  useEffect(() => {
    if (!window.TencentCaptcha) {
      const script = document.createElement('script')
      script.src = 'https://turing.captcha.qcloud.com/TCaptcha.js'
      script.async = true
      document.getElementById('regPageOverSea') &&
        document.getElementById('regPageOverSea').appendChild(script)
    }
  }, [])

  useEffect(() => {
    let timer
    if (if_b) {
      timer = setInterval(() => {
        setCd((x) => x - 1)
        if (countdown <= 1) {
          setCd(60)
          setBeg(false)
        }
      }, 1000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [if_b, countdown])

  useEffect(() => {
    window.wx.ready(function () {
      window.wx.hideOptionMenu()
    })
  }, [])

  const getVerify = () => {
    if (!tel) {
      Toast.info('请输入手机号', 1.2)
      return
    } else if (if_b) {
      return
    }

    function showCaptchaAndRegister() {
      createCaptcha({ showCaptcha, captchaId })
        .then((res) =>
          request({
            url: 'Shop.AbroadRegisterVerifycode',
            method: 'POST',
            body: qs.stringify({
              MemberPhone: tel,
              Type: 1,
              CountryId: countryid,
              ticket: res.ticket,
              randStr: res.randstr,
              captcha_appid: captchaId,
            }),
          })
        )
        .then(function (res) {
          if (res.flag === 200) {
            setBeg(true)
            Toast.success('发送成功', 1.5)
          } else if (res.flag === 410) {
            Modal.alert(
              '',
              <>
                该账号已完成注册
                <br />
                下载APP立即体验
              </>,
              [
                { text: '取消' },
                {
                  text: '去下载',
                  onPress: () => props.history.push('/regdone'),
                },
              ]
            )
          } else if (res.flag === 415) {
            Toast.info(res.msg, 5)
          } else {
            Toast.info(res.msg)
          }
        })
        .catch(() => {})
    }

    if (isChinaMainlandPhoneNumber(tel)) {
      Modal.alert(
        '提示',
        '当前页面为中国港澳台/海外用户注册页面，是否前往中国大陆用户注册页面继续操作？',
        [
          { text: '否', onPress: () => showCaptchaAndRegister() },
          {
            text: '是',
            onPress: () => props.history.push(`/regstep?phone=${tel}`),
          },
        ]
      )
    } else {
      showCaptchaAndRegister()
    }
  }

  const rsa = (pwd) => {
    let encrypt = new JSEncrypt(),
      publicCode =
        'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDw+5+qn3cG6ZrkpwBpBFlx1a8t1PaVfxLQ1XhWz1OkK9B568P5WUU1lLkNKAm8QJyvh9/vXlMvS2JREnSmCY2Q7YarxKtqq34L6hLBh0attf5vUr9U8x6vcDwbrcNdak/uCJKcV0dYLjV0FKs4iLOAZmk40leZmbmu0pm+ckzG+QIDAQAB'
    encrypt.setPublicKey(publicCode)
    return encrypt.encrypt(pwd)
  }

  const sub = throttle(1500, () => {
    if (!tel) {
      Toast.info('请输入正确手机号', 1.2)
      return
    } else if (!verify) {
      Toast.info('请输入验证码', 1.2)
      return
    } else if (!password) {
      Toast.info('请输入密码', 1.2)
      return
    } else if (!name) {
      Toast.info('请输入用户名', 1.2)
      return
    } else if (!shower) {
      Toast.info('请阅读用户协议', 1.2)
      return
    }
    // else if (!area || area.length < 3 || area.indexOf(null) > -1) {
    //   Toast.info("请选择正确所在地", 1.2);
    //   return;
    // }

    Toast.loading('请稍候', 0, null, true)

    request({
      url: 'Shop.AbroadRegister',
      method: 'POST',
      body: qs.stringify({
        ShopID: localStorage.getItem('reg_shopid'),
        VerifyCode: verify,
        InviteCode: invite,
        MemberName: name,
        MemberPwd: rsa(password),
        MemberPhone: tel,
        // Province: area[0],
        // City: area[1],
        // Area: area[2],
        CountryId: countryid,
        //Address: address
      }),
    }).then(function (res) {
      Toast.hide()
      if (res.flag === 200) {
        Toast.success(
          '注册成功！',
          1.4,
          () => props.history.push('/regdone'),
          true
        )
      } else {
        Toast.info(res.msg, 1.4)
      }
    })
  })

  const ant_protocal = (t) => {
    setPT(t)
    setVisible(true)
  }

  const handleCancel = () => setVisible(false)

  useEffect(() => {
    request({
      url: 'Area.AbroadList&status=2',
      method: 'POST',
    }).then(function (res) {
      const { data } = res

      const countryList = data.map((it) => {
        return {
          label: `${it.country_name} （${it.country_region_num}）`,
          value: it.country_id,
        }
      })
      setCountryList(countryList)
      setCountryid(data[0].country_id)
    })
  }, [])

  return (
    <div
      style={{ minHeight: '100vh', paddingTop: 8, paddingBottom: 50 }}
      className="reg-page"
      id="regPageOverSea"
    >
      <div
        className="row-line-box reg-grey-lines reg-row"
        style={{ marginTop: 15 }}
      >
        <img src={require('../../images/cellph.png').default} alt="" />
        <Picker
          data={countryList}
          value={[countryid]}
          disabled={!!localCountry}
          cols={[1]}
          onOk={(e) => {
            setCountryid(e[0])
          }}
          format={(text) => {
            if (text.length) {
              return text[0].match(/（(\S*)）/)[1]
            } else {
              return text
            }
          }}
        >
          <List.Item arrow="horizontal"></List.Item>
        </Picker>
        <InputItem
          ref={inputRef1}
          placeholder="请输入手机号码"
          maxLength={11}
          type="number"
          value={tel}
          readOnly={!!localPhone}
          onChange={(e) => setTel(e)}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 35px 13px',
        }}
        onClick={() => inputRef2.current.focus()}
      >
        <div
          className="reg-grey-lines reg-row"
          style={{ width: '63%', margin: 0 }}
        >
          <img src={require('../../images/locker.png').default} alt="" />
          <InputItem
            ref={inputRef2}
            placeholder="请输入短信验证码"
            value={verify}
            onChange={(e) => setVerify(e)}
          />
        </div>
        <button
          className={`getVerify ${if_b ? 'getVerify--disabled' : ''}`}
          disabled={if_b}
          onClick={getVerify}
        >
          {if_b ? '剩余' + countdown + '秒' : '获取验证码'}
        </button>
      </div>
      <div
        className="row-line-box reg-grey-lines reg-row"
        onClick={() => inputRef3.current.focus()}
      >
        <img src={require('../../images/sq-password.png').default} alt="" />
        <InputItem
          ref={inputRef3}
          placeholder="请设置登录密码"
          value={password}
          onChange={(e) => setPassword(e)}
        />
      </div>
      <div
        className="row-line-box reg-grey-lines reg-row"
        onClick={() => inputRef4.current.focus()}
      >
        <img src={require('../../images/person-reg.png').default} alt="" />
        <InputItem
          ref={inputRef4}
          placeholder="请输入您的店铺名"
          value={name}
          onChange={(e) => setName(e)}
        />
      </div>
      <div className="row-line-box reg-grey-lines reg-row">
        <img src={require('../../images/invite-code.png').default} alt="" />
        <InputItem readOnly value={invite || ''} />
      </div>
      {/* <div className="row-line-box reg-grey-lines" style={{ borderBottom: 'none', borderTop: '1px solid #eaeaea', marginTop: 10 }}>
                <InputItem placeholder="详细地址" value={address} onChange={(e) => setAdd(e)} />
            </div> */}

      <div
        className="row-line-box"
        style={{
          alignItems: 'flex-start',
          margin: '0 35px',
          padding: 0,
          border: 'none',
        }}
      >
        <div style={{ width: '6%', display: 'flex', alignItems: 'center' }}>
          {!shower ? (
            <span className="darkness" onClick={() => setShower(true)}></span>
          ) : (
            <Icon
              type="check-circle"
              size="xs"
              color="#3eb69d"
              style={{ marginTop: 2 }}
              onClick={() => setShower(false)}
            />
          )}
        </div>

        <div style={{ marginLeft: 5, maxWidth: '90%' }}>
          我已同意并阅读
          <span onClick={() => ant_protocal(1)}>
            <span style={{ color: '#3eb69d' }}>
              《{config.appName}平台服务协议》
            </span>
          </span>
          及
          <span onClick={() => ant_protocal(2)}>
            <span style={{ color: '#3eb69d' }}>
              《{config.appName}隐私协议》
            </span>
          </span>
        </div>
      </div>

      <button type="button" className="sub" onClick={sub}>
        免 费 注 册
      </button>
      <div style={{ textAlign: 'center' }}>
        <Link className="return-oversea" to="/regstep">
          中国大陆用户注册 <Icon type="right" size="xs" />
        </Link>
      </div>
      <div
        className="logo"
        style={{ marginBottom: 20, marginTop: 30, textAlign: 'center' }}
      >
        <img
          src={require(`../../images/${dir}/nav-logo.png`).default}
          alt=""
          style={{ width: 'auto', height: 37 }}
        />
      </div>
      <ShowEditModal
        visible={visible}
        popupVal={true}
        handleCancel={handleCancel}
        anType="slide-up"
        transparent={false}
        formContent={protocalType === 1 ? <ToS /> : <Privacy />}
      />
    </div>
  )
}

export default RegStep
